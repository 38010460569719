import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { diagnosticDetail, checkReparateurOrder, sendDiagnoAdminClientOrder, acceptDiagnoAdminReparOrder, refuseDiagnoAdminReparOrder } from '../../redux/actions/orderActions'
import { reparateurList } from '../../redux/actions/userActions'
import DefaultLayout from '../../layouts/DefaultLayout';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import { refuseDiagnoAdminReparReducer } from '../../redux/reducers/orderReducers';
import firestore from '../../firebase';

function DiagnosticDetailScreen() {
    let { id } = useParams();
    const [reparateur, setReparateur] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const detailDiagnostic = useSelector(state => state.diagnosticDetail)
    const { loading, error, order } = detailDiagnostic

    const checkReparOrder = useSelector(state => state.checkReparOrder)
    const { loadingCheck, errorCheck } = checkReparOrder

    const listreparateur = useSelector(state => state.reparateurList)
    const { users } = listreparateur

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin


    const redirect = '/'
    useEffect(() => {
        if (!userInfo) {
            navigate(redirect)
        } else {
            dispatch(diagnosticDetail(id));
            dispatch(reparateurList());
            const collectionRef = firestore.collection('notification');
            collectionRef.onSnapshot((snapshot) => {
                var check = false;
                if (!snapshot.empty) { 
                    snapshot.docChanges().map((change) => {
                        if (change.type === 'added' || change.type === 'modified') {
                            if(change.doc.data()["is_vue"]===false ){
                                if(change.doc.data()["is_admin"]===true ){
                                    check=true;
                                    change.doc.ref.update({ is_vue: true });
                                }
                                // else if(["Diagnostic","Tarif","Command"].includes(change.doc.data()["type_notification"])){
                                //     check=true;
                                // }
                            }
                        }
                    });
                    if(check){
                        dispatch(diagnosticDetail(id));
                    }
                }
            });
        }

    }, [navigate, userInfo, dispatch, id])


    const handleChangeReparateur = (event) => {
        setReparateur(event.target.value);

    };


    const handleSubmitReparateur = (event) => {
        setShowModal(false)
        if (reparateur !== "") {
            dispatch(checkReparateurOrder(id, reparateur));
            window.location.reload();
        }
    };

    const someDiagnoReparateur = (diagnos) => {
        var total = 0;
        for (let index = 0; index < diagnos.length; index++) {
            const diagno = diagnos[index];
            total += diagno.price_reparateur
        }
        return total;
    }

    const someDiagnoAdmin = (diagnos) => {

        return parseFloat(barretteRam) + parseFloat(carteMere) + parseFloat(connecteur) + parseFloat(batterieDefectueux) + parseFloat(chargeur) + parseFloat(ecran);
    }

    const [barretteRam, setBarretteRam] = useState(0);
    const [carteMere, setCarteMere] = useState(0);
    const [connecteur, setConnecteur] = useState(0);
    const [batterieDefectueux, setBatterieDefectueux] = useState(0);
    const [chargeur, setChargeur] = useState(0);
    const [ecran, setEcran] = useState(0);
    const [eventType, setEventType] = useState('');
    const [orderId, setOrderId] = useState('');
    const [errorValid, setErrorValid] = useState('');
    const [showDiag, setShowDiag] = useState(false);
    const [loadEvent, setLoadEvent] = useState(false);
    const [diagnoValue, setDiagnoValue] = useState([]);


    const hundleShowModel = (id, type) => {
        setEventType(type);
        setOrderId(id);
        setShowDiag(true);
    }

    const hundleHideModel = () => {
        setShowDiag(false);
        setLoadEvent(false);
        setEventType('');
        setOrderId('');
    }

    const hundleConfirmEvent = async () => {
        setErrorValid('');
        console.log("start");
        if (eventType === 'diagno') {
            setLoadEvent(true)
            const diag = []
            var check = false;
            for (let index = 0; index < diagnoValue.length; index++) {
                const element = diagnoValue[index];
                if (element.name === "La barrette de ram est défectueuse") {
                    if (barretteRam === 0) {
                        check = true
                    }
                    diag.push({ "id": element.id, "price": barretteRam })
                } else if (element.name === "La carte mère est défectueuse") {
                    if (carteMere === 0) {
                        check = true
                    }
                    diag.push({ "id": element.id, "price": carteMere })
                } else if (element.name === "le connecteur de charge est défectueux") {
                    if (connecteur === 0) {
                        check = true
                    }
                    diag.push({ "id": element.id, "price": connecteur })
                } else if (element.name === "La batterie est défectueux") {
                    if (batterieDefectueux === 0) {
                        check = true
                    }
                    diag.push({ "id": element.id, "price": batterieDefectueux })
                } else if (element.name === "Le chargeur est défectueux") {
                    if (chargeur === 0) {
                        check = true
                    }
                    diag.push({ "id": element.id, "price": chargeur })
                } else if (element.name === "L’écran est défectueux") {
                    if (ecran === 0) {
                        check = true
                    }
                    diag.push({ "id": element.id, "price": ecran })
                }

            }

            if (!check) {
                await dispatch(sendDiagnoAdminClientOrder(orderId, diag)).then(() => { })
                await dispatch(diagnosticDetail(id)).then(() => { })
                hundleHideModel()
            } else {
                setErrorValid("veuillez vérifier le prix du diagnostic ")
                hundleHideModel()
            }

        } else if (eventType === "accptdiagno") {
            await dispatch(acceptDiagnoAdminReparOrder(orderId)).then(() => { })
            await dispatch(diagnosticDetail(id)).then(() => { })
            hundleHideModel()
        } else if (eventType === "refusediagno") {
            await dispatch(refuseDiagnoAdminReparOrder(orderId)).then(() => { })
            await dispatch(diagnosticDetail(id)).then(() => { })
            hundleHideModel()

        } else {

            hundleHideModel()
        }
    }


    return (
        <DefaultLayout>
            {
                loading ? (
                    <Loader />
                ) : error ? (
                    <Alert type="error" message={error} />
                ) : (
                    <>
                        {errorValid && <Alert type="error" message={errorValid} />}
                        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
                            <div className="py-6 px-4 md:px-6 xl:px-7.5">
                                <h4 className="text-xl font-semibold text-black dark:text-white">
                                    Mes diagnostics
                                </h4>
                            </div>
                            {/* detail */}
                            <div className="flex flex-wrap ">
                                {/*  */}
                                <div className="w-full md:w-1/2 mb-4">
                                    <div className='flex flex-wrap px-3'>
                                        <div className="w-full h-8">
                                            <div className='flex'>
                                                <div className="flex-1  text-xs font-bold">
                                                    Code
                                                </div>
                                                <div className="flex-1  text-xs font-bold">
                                                    Système
                                                </div>
                                                <div className="flex-1  text-xs font-bold">
                                                    Type
                                                </div>
                                                <div className="flex-1 text-xs font-bold">
                                                    Marque, Gamme, Modèle
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full border-2 border-[#6397E5] rounded-lg  px-2 py-8 bg-[#F1F7FF]">
                                            <div className='flex justify-center items-center'>
                                                <div className="flex-1  text-xs font-bold">
                                                    N°{order.id}
                                                </div>
                                                <div className="flex-1  text-xs font-bold">
                                                    {order.appariel?.system_type}
                                                </div>
                                                <div className="flex-1  text-xs font-bold">
                                                    {order.appariel?.appariel_type}
                                                </div>
                                                <div className="flex-1 text-xs font-bold">
                                                    {order.appariel?.marque_name + " - " + order.appariel?.marque_gamme + " - " + order.appariel?.marque_modele}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/*  */}
                                <div className="w-full md:w-1/2 mb-4 ">
                                    <div className='flex flex-wrap px-3'>
                                        <div className="w-full  text-xs font-bold h-8">
                                            Demande
                                        </div>
                                        <div className="w-full border-2 border-[#6397E5] rounded-lg  px-2 py-8 bg-[#F1F7FF]">
                                            <div className='w-full text-xs text-[#003E9B]'>Indiqué par Repar’up :</div>
                                            {order.symptomes?.map((symptome, id) => (
                                                <div className='w-full text-xs'>{symptome.name}</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {/*  */}
                                <div className="w-full  mb-4 ">
                                    <div className='flex flex-wrap  px-3 '>
                                        <div className="w-full text-xs font-bold h-8">
                                            Statut Client
                                        </div>
                                        <div className="w-full border-2 border-[#6397E5] rounded-lg  px-2 py-8 bg-[#F1F7FF]">
                                            {
                                                order.status === 4 ?
                                                    <div>
                                                        <div className='flex justify-center items-center'>
                                                            <div className='flex-1'>
                                                                {order.diagnos?.map((diagno) => (
                                                                    <div className='flex mb-2 justify-center items-center'>
                                                                        <div className='flex-1 text-xs'>{diagno.name}</div>
                                                                        {
                                                                            diagno.name === "La barrette de ram est défectueuse" ?
                                                                                <input type='number' step={0.01} className=' border rounded border-[#DFE1E6] text-[#003E9B] px-3 w-24 mx-3' value={barretteRam} onChange={(v) => setBarretteRam(v.target.value)} />
                                                                                : diagno.name === "La carte mère est défectueuse" ?
                                                                                    <input type='number' step={0.01} className=' border rounded border-[#DFE1E6] text-[#003E9B] px-3 w-24 mx-3' value={carteMere} onChange={(v) => setCarteMere(v.target.value)} />
                                                                                    : diagno.name === "le connecteur de charge est défectueux" ?
                                                                                        <input type='number' step={0.01} className=' border rounded border-[#DFE1E6] text-[#003E9B] px-3 w-24 mx-3' value={connecteur} onChange={(v) => setConnecteur(v.target.value)} />
                                                                                        : diagno.name === "La batterie est défectueux" ?
                                                                                            <input type='number' step={0.01} className=' border rounded border-[#DFE1E6] text-[#003E9B] px-3 w-24 mx-3' value={batterieDefectueux} onChange={(v) => setBatterieDefectueux(v.target.value)} />
                                                                                            : diagno.name === "Le chargeur est défectueux" ?
                                                                                                <input type='number' step={0.01} className=' border rounded border-[#DFE1E6] text-[#003E9B] px-3 w-24 mx-3' value={chargeur} onChange={(v) => setChargeur(v.target.value)} />
                                                                                                : diagno.name === "L’écran est défectueux" ?
                                                                                                    <input type='number' step={0.01} className=' border rounded border-[#DFE1E6] text-[#003E9B] px-3 w-24 mx-3' value={ecran} onChange={(v) => setEcran(v.target.value)} />
                                                                                                    : null
                                                                        }
                                                                    </div>
                                                                ))}
                                                                {order.diagnos?.length > 0 ? <hr className='my-2 w-1/2' /> : null}
                                                                <div className='flex justify-center items-center'>
                                                                    <div className='w-full text-xs'>Delais d’intervention: <span className='font-bold text-[#003E9B]'>{order.delais_intervention}</span></div>
                                                                </div>
                                                            </div>
                                                            <div className='mx-3 text-center'>
                                                                <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Prix total envoyer <br /> au client : <span className='text-[#003E9B]'> {someDiagnoAdmin(order.diagnos)}€</span></div>

                                                                <button onClick={() => {
                                                                    setDiagnoValue(order.diagnos);
                                                                    hundleShowModel(order.id, 'diagno')
                                                                }} className=' my-3 inline-flex items-center justify-center rounded-full bg-primary py-2 px-5 text-center font-medium text-white hover:bg-opacity-90 text-xs mx-1' >
                                                                    Envoyer le <br /> prix au client
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8 || order.status === 9 || order.status === 10 || order.status === 11 ?
                                                        <div>
                                                            <div className='flex justify-center items-center'>
                                                                <div className='flex-1'>
                                                                    <div className='w-full text-xs text-[#003E9B]'>Envoyé au client :</div>
                                                                    {order.diagnos?.map((diagno) => (
                                                                        <div className='flex mb-2 justify-center items-center'>
                                                                            <div className='flex-1 text-xs'>{diagno.name} : <span className='font-bold text-[#003E9B]'>{diagno.price}€</span></div>
                                                                        </div>
                                                                    ))}
                                                                    {order.diagnos?.length > 0 ? <hr className='my-2 w-1/2' /> : null}
                                                                    <div className='flex justify-center items-center'>
                                                                        <div className='w-full text-xs'>Delais d’intervention: <span className='font-bold text-[#003E9B]'>{order.delais_intervention}</span></div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    order.status === 5 ?
                                                                        <div className='mx-3 text-center'>
                                                                            <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réponse en attente du client</div>
                                                                        </div>

                                                                        : order.status === 6 || order.status === 7 ?
                                                                            order.is_accept && !order.is_refuse ?
                                                                                <div className='mx-3 text-center'>
                                                                                    <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réparation acceptée par le client</div>
                                                                                </div>
                                                                                : order.is_refuse ?
                                                                                    <div className='mx-3 text-center'>
                                                                                        <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réparation refusé par le client</div>
                                                                                    </div>
                                                                                    : <></>


                                                                            : order.status === 7 ?
                                                                                <div className='mx-3 text-center'>
                                                                                    <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réparation refusé par le client</div>
                                                                                </div>
                                                                                : order.status === 8 ?
                                                                                    order.is_accept && !order.is_refuse ?
                                                                                        <div className='mx-3 text-center'>
                                                                                            <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'>Réparation acceptée par le client <br /><span className='font-bold'>En cours de réparation</span></div>
                                                                                        </div>
                                                                                        : order.is_refuse ?
                                                                                            <div className='mx-3 text-center'>
                                                                                                <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réparation refusé par le client</div>
                                                                                            </div>
                                                                                            : <div className='mx-3 text-center'>
                                                                                                <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réponse en attente du client</div>
                                                                                            </div>
                                                                                    : order.status === 9 ?
                                                                                        order.is_accept && !order.is_refuse ?
                                                                                            <div className='mx-3 text-center'>
                                                                                                <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'>Réparation acceptée par le client <br /><span className='font-bold'>En cours de réparation</span></div>
                                                                                            </div>
                                                                                            : order.is_refuse ?
                                                                                                <div className='mx-3 text-center'>
                                                                                                    <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réparation refusé par le client</div>
                                                                                                </div>
                                                                                                : <div className='mx-3 text-center'>
                                                                                                    <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réparation refusé par Repar’up</div>
                                                                                                </div>
                                                                                        : order.status === 10 ?
                                                                                            <div className='mx-3 text-center'>
                                                                                                {
                                                                                                    order.deliveries[order.deliveries.length - 1].type === "aller" ?
                                                                                                        <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'> Réparation terminée
                                                                                                            avec succès 🏆 </div>
                                                                                                        : !order.deliveries[order.deliveries.length - 1].is_start || !order.deliveries[order.deliveries.length - 1].livreur?.id ?
                                                                                                            <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'> Réparation terminée
                                                                                                                avec succès 🏆 </div>
                                                                                                            : !order.deliveries[order.deliveries.length - 1].is_complete ?
                                                                                                                <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'> {order.steps[0].title} </div>
                                                                                                                : <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'> Le client a récuperé
                                                                                                                    son appareil ♻ </div>
                                                                                                }

                                                                                            </div>
                                                                                            : order.status === 11 ?
                                                                                                <div className='mx-3 text-center'>

                                                                                                    {
                                                                                                        order.deliveries[order.deliveries.length - 1].type === "aller" ?
                                                                                                            <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'> Réparation impossible </div>
                                                                                                            : !order.deliveries[order.deliveries.length - 1].is_start || !order.deliveries[order.deliveries.length - 1].livreur?.id ?
                                                                                                                <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'> Réparation impossible </div>
                                                                                                                : !order.deliveries[order.deliveries.length - 1].is_complete ?
                                                                                                                    <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'> {order.steps[0].title} </div>
                                                                                                                    : <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'> Le client a récuperé
                                                                                                                        son appareil ♻ </div>
                                                                                                    }

                                                                                                </div>
                                                                                                : <>dd</>

                                                                }

                                                            </div>
                                                        </div>


                                                        : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* reparation */}
                            <div className="flex justify-end mt-8 mb-8 ">
                                <div className="w-full mb-4">
                                    <div className='flex flex-wrap px-3'>
                                        <div className="w-full h-8">
                                            <div className='flex justify-center items-center'>
                                                <div className="flex-1  text-xs font-bold">
                                                    Réparateur
                                                </div>
                                                <div className="flex-1  text-xs font-bold">
                                                    Diagnostic
                                                </div>
                                                <div className="flex-1  text-xs font-bold">
                                                    Statut Atelier
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full border-2 border-[#6397E5] rounded-lg  px-2 py-8 bg-[#F1F7FF]">
                                            <div className='flex justify-center items-center'>
                                                <div className="flex-1  text-xs font-bold">
                                                    {
                                                        !order.reparateur?.id ?
                                                            <div className="relative  bg-white dark:bg-form-input mr-4">
                                                                <select value={reparateur} onChange={handleChangeReparateur} className="relative w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                                                                    <option value="">Choisir le réparateur</option>
                                                                    {
                                                                        users?.map((user, id) => (
                                                                            <option value={user.id}>{user.first_name} {user.last_name} </option>
                                                                        ))
                                                                    }

                                                                </select>
                                                                <span className="absolute top-1/2 right-2 z-30 -translate-y-1/2">
                                                                    <svg
                                                                        className="fill-current"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <g opacity="0.8">
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                                                                fill=""
                                                                            ></path>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </div> : <div className='text-center bg-white border border-[#DFE1E6] w-max mx-5 px-5 py-2 rounded-md'>{order.reparateur?.first_name} {order.reparateur?.last_name} </div>
                                                    }
                                                </div>
                                                <div className="flex-1  text-xs font-bold ">
                                                    {
                                                        order.status === 1 || order.status === 2 || order.status === 3 ?
                                                            <>
                                                                <div className='w-full text-xs text-[#003E9B]'>Indiqué par Repar’up :</div>
                                                                {order.symptomes?.map((symptome, id) => (
                                                                    <div className='min-w-max text-xs '>{symptome.name}</div>
                                                                ))}
                                                            </>
                                                            : <>
                                                                <div className='w-full text-xs text-[#7FD082]'>Indiqué par L’atelier :</div>
                                                                {order.diagnos?.map((diagn, id) => (
                                                                    <div className='w-full text-xs'>{diagn.name}: <span className='font-bold text-[#7FD082]'>{diagn.price_reparateur}€</span></div>
                                                                ))}
                                                                <hr className='w-1/2 my-2' />
                                                                <div className='w-full text-xs'>Delais d’intervention: <span className='font-bold text-[#7FD082]'>{order.delais_intervention}</span></div>
                                                            </>
                                                    }

                                                </div>
                                                <div className="flex-1  justify-center items-center text-xs font-bold">
                                                    {!order.reparateur?.id ? <button onClick={() => setShowModal(true)} className="text-xs inline-flex items-center justify-center rounded-full bg-primary py-3 px-4 text-center font-medium text-white hover:bg-opacity-90 ">Envoyer l’appareil à l’atelier
                                                    </button> : <>
                                                        {
                                                            order.status === 1 ?
                                                                !order.is_reparateur ?
                                                                    <div className='text-center bg-white border border-[#DFE1E6] min-w-min mx-5 px-5 py-2 rounded-md text-[#7FD082] border-dashed'>En attente d'un l'Atelier qui accepte la Commande</div>
                                                                    :
                                                                    order.deliveries?.length > 0 ?
                                                                        order.deliveries[order.deliveries.length - 1].is_complete === false ?
                                                                            order.deliveries[order.deliveries.length - 1].livreur?.id ?
                                                                                order.steps?.length > 0 ?
                                                                                    <div className='text-center bg-white border border-[#DFE1E6] min-w-min mx-5 px-5 py-2 rounded-md text-[#7FD082] border-dashed'>{order.steps[0].title}</div>
                                                                                    : null
                                                                                : <div className='text-center bg-white border border-[#DFE1E6] min-w-min mx-5 px-5 py-2 rounded-md text-[#7FD082] border-dashed'>En attente d'un livreur qui accepte la livraison</div>
                                                                            // if order delivery is complete
                                                                            : <div className='text-center bg-white border border-[#DFE1E6] min-w-min mx-5 px-5 py-2 rounded-md text-[#7FD082] border-dashed'>L’appareil a été récupéré par l’atelier</div>
                                                                        // end if order delivery iscomlete
                                                                        : null
                                                                : order.status === 2 ?
                                                                    <div className='text-center bg-white border border-[#DFE1E6] min-w-min mx-5 px-5 py-2 rounded-md text-[#7FD082] border-dashed'>L’appareil a été récupéré par l’atelier</div>
                                                                    : order.status === 3 ?
                                                                        <div className='text-center bg-white border border-[#DFE1E6] min-w-min mx-5 px-5 py-2 rounded-md text-[#7FD082] border-dashed'>Diagnostic en attente de l’atelier</div>
                                                                        : order.status === 4 || order.status === 5 || order.status === 6 || order.status === 7 ?
                                                                            <div>
                                                                                <div className='text-center bg-white border border-[#DFE1E6] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Prix total reçu par l’atelier : <span className='text-[#7FD082]'> {someDiagnoReparateur(order.diagnos)}€</span></div>
                                                                                <div className='mx-auto my-3 text-center '>
                                                                                    <button onClick={() => {
                                                                                        hundleShowModel(order.id, 'refusediagno')
                                                                                    }} className='inline-flex items-center justify-center rounded-full bg-[#D65659] py-2 px-5 text-center font-medium text-white hover:bg-opacity-90 text-xs mx-1' >
                                                                                        Refuser
                                                                                    </button>
                                                                                    <button onClick={() => {
                                                                                        hundleShowModel(order.id, 'accptdiagno')
                                                                                    }} className='inline-flex items-center justify-center rounded-full bg-[#3AB83F] py-2 px-5 text-center font-medium text-white hover:bg-opacity-90 text-xs mx-1'>
                                                                                        Accepter
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            : order.status === 8 ?
                                                                                <div className='mx-3 text-center'>
                                                                                    <div className='text-center bg-white border border-[#7FD082] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#7FD082]'>Réparation acceptée par Repar’up <br /><span className='font-bold'>En cours de réparation</span></div>
                                                                                </div>
                                                                                : order.status === 9 ?
                                                                                    order.is_accept && !order.is_refuse ?
                                                                                        <div className='mx-3 text-center'>
                                                                                            <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#003E9B]'>Réparation acceptée par le client <br />
                                                                                                <span className='font-bold'>Réparation refusé par Repar’up</span></div>
                                                                                        </div>
                                                                                        : order.is_refuse ?
                                                                                            <div className='mx-3 text-center'>
                                                                                                <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réparation refusé par le client</div>
                                                                                            </div>
                                                                                            : <div className='mx-3 text-center'>
                                                                                                <div className='text-center bg-white border border-[#003E9B] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed'>Réparation refusé par Repar’up</div>
                                                                                            </div>
                                                                                    : order.status === 10 ?
                                                                                        <div className='mx-3 text-center'>
                                                                                            {
                                                                                                order.deliveries[order.deliveries.length - 1].type === "aller" ?
                                                                                                    <div className='text-center bg-white border border-[#7FD082] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#7FD082]'> Réparation terminée
                                                                                                        avec succès 🏆 </div>
                                                                                                    : !order.deliveries[order.deliveries.length - 1].is_start || !order.deliveries[order.deliveries.length - 1].livreur?.id ?
                                                                                                        <div className='text-center bg-white border border-[#7FD082] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#7FD082]'> Réparation terminée
                                                                                                            avec succès 🏆 </div>
                                                                                                        : !order.deliveries[order.deliveries.length - 1].is_complete ?
                                                                                                            <div className='text-center bg-white border border-[#7FD082] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#7FD082]'> {order.steps[0].title} </div>
                                                                                                            : <div className='text-center bg-white border border-[#7FD082] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#7FD082]'> Le client a récuperé
                                                                                                                son appareil ♻ </div>


                                                                                            }

                                                                                        </div>
                                                                                        : order.status === 11 ?
                                                                                            <div className='mx-3 text-center'>
                                                                                                {
                                                                                                    order.deliveries[order.deliveries.length - 1].type === "aller" ?
                                                                                                        <div className='text-center bg-white border border-[#7FD082] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#7FD082]'> Réparation impossible </div>
                                                                                                        : !order.deliveries[order.deliveries.length - 1].is_start || !order.deliveries[order.deliveries.length - 1].livreur?.id ?
                                                                                                            <div className='text-center bg-white border border-[#7FD082] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#7FD082]'> Réparation impossible
                                                                                                            </div>
                                                                                                            : !order.deliveries[order.deliveries.length - 1].is_complete ?
                                                                                                                <div className='text-center bg-white border border-[#7FD082] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#7FD082]'> {order.steps[0].title} </div>
                                                                                                                : <div className='text-center bg-white border border-[#7FD082] min-w-min mx-5 px-5 py-2 rounded-md font-normal border-dashed text-[#7FD082]'> Le client a récuperé
                                                                                                                    son appareil ♻ </div>


                                                                                                }

                                                                                            </div>
                                                                                            : null

                                                        }


                                                    </>}
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* model */}
                        {showModal ? (
                            <>
                                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
                                    <div className="bg-white p-6 rounded shadow-md">
                                        <h3 className="text-lg font-bold mb-4">{reparateur !== "" ? "Confirmation" : "Oops!"}</h3>
                                        <p className="mb-4">{reparateur !== "" ? "Voulez-vous confirmer votre choix ?" : "Veuillez sélectionner un réparateur"}</p>
                                        <div className="flex justify-end">
                                            {
                                                reparateur !== "" ? <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={handleSubmitReparateur}> Confirm </button> : null
                                            }
                                            <button className="bg-danger hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" onClick={() => setShowModal(false)}> Annuler </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        {showDiag ? (
                            <>
                                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
                                    <div className="bg-white p-6 rounded shadow-md">
                                        <h3 className="text-lg font-bold mb-4">Confirmation</h3>
                                        <p className="mb-4">Voulez-vous confirmer votre choix ?</p>
                                        <div className="flex justify-end">
                                            <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" onClick={() => hundleConfirmEvent()} disabled={loadEvent}> {loadEvent ? <div role="status">
                                                <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-danger" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg>
                                                <span className="sr-only">Loading...</span>
                                            </div> : "Confirm"}  </button>
                                            <button className="bg-danger hover:bg-gray-600 text-white font-bold py-2 px-4 rounded" onClick={() => hundleHideModel()} disabled={loadEvent}> Annuler </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </>
                )
            }

        </DefaultLayout >
    )
}

export default DiagnosticDetailScreen