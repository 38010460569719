import axios from '../../axios'

import {
    NOTIFICATION_LIST_FAIL,
    NOTIFICATION_LIST_REQUEST,
    NOTIFICATION_LIST_SUCCESS,
    // 
} from '../constants/notificationConstants'


export const notificationList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: NOTIFICATION_LIST_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.get(
            '/notifications/',
            config,
        )

        dispatch({
            type: NOTIFICATION_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NOTIFICATION_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}