import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DefaultLayout from '../../layouts/DefaultLayout'

function DashboardScreen() {
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo, error, loading, } = userLogin

    const redirect = '/'
    useEffect(() => {
        if (!userInfo) {
            navigate(redirect)
        }
    }, [navigate, userInfo])
    return (
        <div>
            <DefaultLayout>
                DashboardScreen
            </DefaultLayout>
        </div>
    )
}

export default DashboardScreen
