import React from 'react'
import DefaultLayout from '../../layouts/DefaultLayout'

function ProfileScreen() {
    return (
        <DefaultLayout>
            <div>ProfileScreen</div>
        </DefaultLayout>
    )
}

export default ProfileScreen