// diagno
export const DIAGNO_ORDER_REQUEST = 'DIAGNO_ORDER_REQUEST'
export const DIAGNO_ORDER_SUCCESS = 'DIAGNO_ORDER_SUCCESS'
export const DIAGNO_ORDER_FAIL = 'DIAGNO_ORDER_FAIL'

export const DIAGNO_DETAIL_ORDER_REQUEST = 'DIAGNO_DETAIL_ORDER_REQUEST'
export const DIAGNO_DETAIL_ORDER_SUCCESS = 'DIAGNO_DETAIL_ORDER_SUCCESS'
export const DIAGNO_DETAIL_ORDER_FAIL = 'DIAGNO_DETAIL_ORDER_FAIL'

export const DIAGNO_CHECK_ORDER_REQUEST = 'DIAGNO_CHECK_ORDER_REQUEST'
export const DIAGNO_CHECK_ORDER_SUCCESS = 'DIAGNO_CHECK_ORDER_SUCCESS'
export const DIAGNO_CHECK_ORDER_FAIL = 'DIAGNO_CHECK_ORDER_FAIL'


export const DIAGNO_SEBD_DIAG_ORDER_REQUEST = 'DIAGNO_SEBD_DIAG_ORDER_REQUEST'
export const DIAGNO_SEBD_DIAG_ORDER_SUCCESS = 'DIAGNO_SEBD_DIAG_ORDER_SUCCESS'
export const DIAGNO_SEBD_DIAG_ORDER_FAIL = 'DIAGNO_SEBD_DIAG_ORDER_FAIL'

export const DIAGNO_ACCEPT_DIAG_ORDER_REQUEST = 'DIAGNO_ACCEPT_DIAG_ORDER_REQUEST'
export const DIAGNO_ACCEPT_DIAG_ORDER_SUCCESS = 'DIAGNO_ACCEPT_DIAG_ORDER_SUCCESS'
export const DIAGNO_ACCEPT_DIAG_ORDER_FAIL = 'DIAGNO_ACCEPT_DIAG_ORDER_FAIL'

export const DIAGNO_REFUSE_DIAG_ORDER_REQUEST = 'DIAGNO_REFUSE_DIAG_ORDER_REQUEST'
export const DIAGNO_REFUSE_DIAG_ORDER_SUCCESS = 'DIAGNO_REFUSE_DIAG_ORDER_SUCCESS'
export const DIAGNO_REFUSE_DIAG_ORDER_FAIL = 'DIAGNO_REFUSE_DIAG_ORDER_FAIL'

// tarif
export const TARIF_ORDER_REQUEST = 'TARIF_ORDER_REQUEST'
export const TARIF_ORDER_SUCCESS = 'TARIF_ORDER_SUCCESS'
export const TARIF_ORDER_FAIL = 'TARIF_ORDER_FAIL'

// Commande
export const COMMAND_ORDER_REQUEST = 'COMMAND_ORDER_REQUEST'
export const COMMAND_ORDER_SUCCESS = 'COMMAND_ORDER_SUCCESS'
export const COMMAND_ORDER_FAIL = 'COMMAND_ORDER_FAIL'

export const COMMAND_SENDAPP_ORDER_REQUEST = 'COMMAND_SENDAPP_ORDER_REQUEST'
export const COMMAND_SENDAPP_ORDER_SUCCESS = 'COMMAND_SENDAPP_ORDER_SUCCESS'
export const COMMAND_SENDAPP_ORDER_FAIL = 'COMMAND_SENDAPP_ORDER_FAIL'
