import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    // 
    USER_REPUR_SUCCESS,
    USER_REPUR_REQUEST,
    USER_REPUR_FAIL,
    // 
    USER_LIVREUR_SUCCESS,
    USER_LIVREUR_REQUEST,
    USER_LIVREUR_FAIL,
    // 
    USER_CLIENT_SUCCESS,
    USER_CLIENT_REQUEST,
    USER_CLIENT_FAIL,
    // 
} from '../constants/userConstants'

export const clientListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_CLIENT_REQUEST:
            return { loading: true, users: [] };
        case USER_CLIENT_SUCCESS:
            return { loading: false, users: action.payload.users };
        case USER_CLIENT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const livreurListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_LIVREUR_REQUEST:
            return { loading: true, users: [] };
        case USER_LIVREUR_SUCCESS:
            return { loading: false, users: action.payload.users };
        case USER_LIVREUR_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const reparateurListReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case USER_REPUR_REQUEST:
            return { loading: true, users: [] };
        case USER_REPUR_SUCCESS:
            return { loading: false, users: action.payload.users };
        case USER_REPUR_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true };
        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload };
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload };
        case USER_LOGOUT:
            return {};
        default:
            return state;
    }
};


