import axios from "axios";

// const baseURL = "http://192.168.170.116:8000/api";
// this online
const baseURL = "http://15.188.52.23/api";

// const baseURL = "http://backend.ctitechnologie.ma";

const instance = axios.create({
    timeout: 5000,
    baseURL: baseURL,
});

export default instance;
