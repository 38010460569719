
import axios from '../../axios'
import {
    DIAGNO_DETAIL_ORDER_FAIL,
    DIAGNO_DETAIL_ORDER_REQUEST,
    DIAGNO_DETAIL_ORDER_SUCCESS,
    // 
    DIAGNO_ORDER_FAIL,
    DIAGNO_ORDER_REQUEST,
    DIAGNO_ORDER_SUCCESS,
    // 
    DIAGNO_CHECK_ORDER_FAIL,
    DIAGNO_CHECK_ORDER_REQUEST,
    DIAGNO_CHECK_ORDER_SUCCESS,
    // 
    DIAGNO_SEBD_DIAG_ORDER_FAIL,
    DIAGNO_SEBD_DIAG_ORDER_REQUEST,
    DIAGNO_SEBD_DIAG_ORDER_SUCCESS,
    // 
    DIAGNO_ACCEPT_DIAG_ORDER_FAIL,
    DIAGNO_ACCEPT_DIAG_ORDER_REQUEST,
    DIAGNO_ACCEPT_DIAG_ORDER_SUCCESS,
    // 
    TARIF_ORDER_FAIL,
    TARIF_ORDER_REQUEST,
    TARIF_ORDER_SUCCESS,
    // 
    COMMAND_ORDER_FAIL,
    COMMAND_ORDER_REQUEST,
    COMMAND_ORDER_SUCCESS,
    // 
    COMMAND_SENDAPP_ORDER_FAIL,
    COMMAND_SENDAPP_ORDER_REQUEST,
    COMMAND_SENDAPP_ORDER_SUCCESS,
    // 
    DIAGNO_REFUSE_DIAG_ORDER_FAIL,
    DIAGNO_REFUSE_DIAG_ORDER_REQUEST,
    DIAGNO_REFUSE_DIAG_ORDER_SUCCESS,


} from '../constants/orderConstants'

export const sendAppToReparup = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMMAND_SENDAPP_ORDER_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.put(
            '/orders/send-commande-to-reparateur/' + id,
            {},
            config,
        )

        dispatch({
            type: COMMAND_SENDAPP_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: COMMAND_SENDAPP_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}



export const commandeList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMMAND_ORDER_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.get(
            '/orders/commandes/',
            config,
        )

        dispatch({
            type: COMMAND_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: COMMAND_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}

export const addTarifReparateurOrder = (id, tarifs) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DIAGNO_SEBD_DIAG_ORDER_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.post(
            '/orders/add-tarif-by-admin/' + id,
            {
                "tarifs": tarifs,
            },
            config,
        )

        dispatch({
            type: DIAGNO_SEBD_DIAG_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DIAGNO_SEBD_DIAG_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.data,
        })
    }
}

export const tarifList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: TARIF_ORDER_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.get(
            '/orders/tarif/',
            config,
        )

        dispatch({
            type: TARIF_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: TARIF_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}


export const diagnosticList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: DIAGNO_ORDER_REQUEST,

        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.get(
            '/orders/diagnostic/',
            config,
        )

        dispatch({
            type: DIAGNO_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DIAGNO_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}

export const diagnosticDetail = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DIAGNO_DETAIL_ORDER_REQUEST,

        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.get(
            '/orders/detail/' + id,
            config,
        )

        dispatch({
            type: DIAGNO_DETAIL_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DIAGNO_DETAIL_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}



export const checkReparateurOrder = (id, reparateur) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DIAGNO_CHECK_ORDER_REQUEST,

        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.put(
            '/orders/check-rep-order/' + id,
            {
                "reparateur": reparateur,
            },
            config,
        )

        dispatch({
            type: DIAGNO_CHECK_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DIAGNO_CHECK_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}


export const sendDiagnoAdminClientOrder = (id, diagno) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DIAGNO_SEBD_DIAG_ORDER_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.put(
            '/orders/send-admin-diagnostic/' + id,
            {
                "diagnos": diagno,
            },
            config,
        )

        dispatch({
            type: DIAGNO_SEBD_DIAG_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DIAGNO_SEBD_DIAG_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.data,
        })
    }
}

export const acceptDiagnoAdminReparOrder = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DIAGNO_ACCEPT_DIAG_ORDER_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.put(
            '/orders/accept-diagnostic-repar-admin/' + id,
            {},
            config,
        )

        dispatch({
            type: DIAGNO_ACCEPT_DIAG_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DIAGNO_ACCEPT_DIAG_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.data,
        })
    }
}


export const refuseDiagnoAdminReparOrder = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DIAGNO_REFUSE_DIAG_ORDER_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.put(
            '/orders/refuse-diagnostic-repar-admin/' + id,
            {},
            config,
        )

        dispatch({
            type: DIAGNO_REFUSE_DIAG_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DIAGNO_REFUSE_DIAG_ORDER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.data,
        })
    }
}