import {
    DIAGNO_ORDER_SUCCESS,
    DIAGNO_ORDER_REQUEST,
    DIAGNO_ORDER_FAIL,
    // 
    DIAGNO_DETAIL_ORDER_SUCCESS,
    DIAGNO_DETAIL_ORDER_REQUEST,
    DIAGNO_DETAIL_ORDER_FAIL,
    // 
    DIAGNO_CHECK_ORDER_FAIL,
    DIAGNO_CHECK_ORDER_REQUEST,
    DIAGNO_CHECK_ORDER_SUCCESS,
    // 
    DIAGNO_SEBD_DIAG_ORDER_FAIL,
    DIAGNO_SEBD_DIAG_ORDER_REQUEST,
    DIAGNO_SEBD_DIAG_ORDER_SUCCESS,
    // 
    DIAGNO_ACCEPT_DIAG_ORDER_FAIL,
    DIAGNO_ACCEPT_DIAG_ORDER_REQUEST,
    DIAGNO_ACCEPT_DIAG_ORDER_SUCCESS,
    // 
    TARIF_ORDER_SUCCESS,
    TARIF_ORDER_REQUEST,
    TARIF_ORDER_FAIL,
    // 
    COMMAND_ORDER_FAIL,
    COMMAND_ORDER_REQUEST,
    COMMAND_ORDER_SUCCESS,
    // 
    COMMAND_SENDAPP_ORDER_FAIL,
    COMMAND_SENDAPP_ORDER_REQUEST,
    COMMAND_SENDAPP_ORDER_SUCCESS,
    // 
    DIAGNO_REFUSE_DIAG_ORDER_FAIL,
    DIAGNO_REFUSE_DIAG_ORDER_REQUEST,
    DIAGNO_REFUSE_DIAG_ORDER_SUCCESS,
    // 
} from '../constants/orderConstants'

export const sendAppToReparupReducer = (state = {}, action) => {
    switch (action.type) {
        case COMMAND_SENDAPP_ORDER_REQUEST:
            return { loadingCheck: true, };
        case COMMAND_SENDAPP_ORDER_SUCCESS:
            return { loadingCheck: false };
        case COMMAND_SENDAPP_ORDER_FAIL:
            return { loadingCheck: false, errorCheck: action.payload };
        default:
            return state;
    }
};

export const commandeListReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case COMMAND_ORDER_REQUEST:
            return { loading: true, orders: [] };
        case COMMAND_ORDER_SUCCESS:
            return { loading: false, orders: action.payload.orders };
        case COMMAND_ORDER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const tarifListReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case TARIF_ORDER_REQUEST:
            return { loading: true, orders: [] };
        case TARIF_ORDER_SUCCESS:
            return { loading: false, orders: action.payload.orders };
        case TARIF_ORDER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const diagnosticListReducer = (state = { orders: [] }, action) => {
    switch (action.type) {
        case DIAGNO_ORDER_REQUEST:
            return { loading: true, orders: [] };
        case DIAGNO_ORDER_SUCCESS:
            return { loading: false, orders: action.payload.orders };
        case DIAGNO_ORDER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const diagnosticDetailReducer = (state = { order: {} }, action) => {
    switch (action.type) {
        case DIAGNO_DETAIL_ORDER_REQUEST:
            return { loading: true, order: {} };
        case DIAGNO_DETAIL_ORDER_SUCCESS:
            return { loading: false, order: action.payload };
        case DIAGNO_DETAIL_ORDER_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const checkReparOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case DIAGNO_CHECK_ORDER_REQUEST:
            return { loadingCheck: true, };
        case DIAGNO_CHECK_ORDER_SUCCESS:
            return { loadingCheck: false };
        case DIAGNO_CHECK_ORDER_FAIL:
            return { loadingCheck: false, errorCheck: action.payload };
        default:
            return state;
    }
};

export const sendDiagnoAdminClientReducer = (state = {}, action) => {
    switch (action.type) {
        case DIAGNO_SEBD_DIAG_ORDER_REQUEST:
            return { loadingCheck: true, };
        case DIAGNO_SEBD_DIAG_ORDER_SUCCESS:
            return { loadingCheck: false };
        case DIAGNO_SEBD_DIAG_ORDER_FAIL:
            return { loadingCheck: false, errorCheck: action.payload };
        default:
            return state;
    }
};

export const acceptDiagnoAdminReparReducer = (state = {}, action) => {
    switch (action.type) {
        case DIAGNO_ACCEPT_DIAG_ORDER_REQUEST:
            return { loadingAccept: true, };
        case DIAGNO_ACCEPT_DIAG_ORDER_SUCCESS:
            return { loadingAccept: false };
        case DIAGNO_ACCEPT_DIAG_ORDER_FAIL:
            return { loadingAccept: false, errorAccept: action.payload };
        default:
            return state;
    }
};

export const refuseDiagnoAdminReparReducer = (state = {}, action) => {
    switch (action.type) {
        case DIAGNO_REFUSE_DIAG_ORDER_REQUEST:
            return { loadingAccept: true, };
        case DIAGNO_REFUSE_DIAG_ORDER_SUCCESS:
            return { loadingAccept: false };
        case DIAGNO_REFUSE_DIAG_ORDER_FAIL:
            return { loadingAccept: false, errorAccept: action.payload };
        default:
            return state;
    }
};


