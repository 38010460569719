import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { userLoginReducer, reparateurListReducer, livreurListReducer, clientListReducer } from './reducers/userReducers'
import { diagnosticListReducer, diagnosticDetailReducer, checkReparOrderReducer, sendDiagnoAdminClientReducer, acceptDiagnoAdminReparReducer, tarifListReducer, commandeListReducer, refuseDiagnoAdminReparReducer } from './reducers/orderReducers'
import { notificationListReducer } from './reducers/notificationReducers'


const reducer = combineReducers({
    userLogin: userLoginReducer,
    diagnosticList: diagnosticListReducer,
    diagnosticDetail: diagnosticDetailReducer,
    reparateurList: reparateurListReducer,
    checkReparOrder: checkReparOrderReducer,
    sendDiagnoAdminClient: sendDiagnoAdminClientReducer,
    acceptDiagnoAdminRepar: acceptDiagnoAdminReparReducer,
    refuseDiagnoAdminRepar: refuseDiagnoAdminReparReducer,
    // 
    tarifList: tarifListReducer,
    commandList: commandeListReducer,
    // 
    livreurList: livreurListReducer,
    clientList: clientListReducer,
    // 
    notificationList: notificationListReducer,
})

const userInfoFromStorage = localStorage.getItem("userInfoReparup")
    ? JSON.parse(localStorage.getItem("userInfoReparup"))
    : null;

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
};


const middleware = [thunk]


const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))


export default store