// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAO6p45DIQIZa4xWp6I_6w4zBqQLZv-A9A",
  authDomain: "repar-up-project-rdf.firebaseapp.com",
  projectId: "repar-up-project-rdf",
  storageBucket: "repar-up-project-rdf.appspot.com",
  messagingSenderId: "1026309709819",
  appId: "1:1026309709819:web:48684e31fd4521383f355e",
  measurementId: "G-H9WSPRDGJH"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firestore = app.firestore();

export default firestore;