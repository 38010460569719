import axios from '../../axios'
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    // 
    USER_REPUR_SUCCESS,
    USER_REPUR_REQUEST,
    USER_REPUR_FAIL,
    // 
    USER_LIVREUR_SUCCESS,
    USER_LIVREUR_REQUEST,
    USER_LIVREUR_FAIL,
    // 
    USER_CLIENT_SUCCESS,
    USER_CLIENT_REQUEST,
    USER_CLIENT_FAIL,
    // 
} from '../constants/userConstants'

export const clientList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_CLIENT_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.get(
            '/users/clients/',
            config,
        )

        dispatch({
            type: USER_CLIENT_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_CLIENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}


export const livreurList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_LIVREUR_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.get(
            '/users/deliveries/',
            config,
        )

        dispatch({
            type: USER_LIVREUR_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_LIVREUR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}


export const reparateurList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_REPUR_REQUEST,
        })
        var {
            userLogin: { userInfo },
        } = getState();
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access}`,
            },
        };
        const { data } = await axios.get(
            '/users/reparateurs/',
            config,
        )

        dispatch({
            type: USER_REPUR_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_REPUR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}


export const login = (username, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST,
        })
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const { data } = await axios.post(
            '/users/login/',
            { 'username': username, 'password': password, },
            config,
        )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })
        localStorage.setItem('userInfoReparup', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.detail,
        })
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem("userInfoReparup");
    dispatch({ type: USER_LOGOUT });
    document.location.href = "/";
};