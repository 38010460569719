import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { commandeList, tarifList } from '../../redux/actions/orderActions'
import DefaultLayout from '../../layouts/DefaultLayout'
import Loader from '../../components/Loader'
import Alert from '../../components/Alert'
import  firestore  from '../../firebase';


function CommandScreen() {
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const listCommande = useSelector(state => state.commandList)
    const { loading, error, orders } = listCommande


    const redirect = '/'
    useEffect(() => {
        if (!userInfo) {
            navigate(redirect)
        } else {
            dispatch(commandeList());
            const collectionRef = firestore.collection('notification');
            collectionRef.onSnapshot((snapshot) => {
                var check = false;
                if (!snapshot.empty) { 
                    snapshot.docChanges().map((change) => {
                        if (change.type === 'added' || change.type === 'modified') {
                            if(change.doc.data()["is_vue"]===false ){
                                if(change.doc.data()["is_admin"]===true ){
                                    check=true;
                                    change.doc.ref.update({ is_vue: true });
                                }
                                // else if(["Diagnostic","Tarif","Command"].includes(change.doc.data()["type_notification"])){
                                //     check=true;
                                // }
                            }
                        }
                    });
                    if(check){
                        dispatch(commandeList());
                    }
                }
            });
              

        }

    }, [navigate, userInfo])



    return (
        <DefaultLayout>
            {loading ? (
                <Loader />
            ) : error ? (
                <Alert type="error" message={error} />
            ) : (
                <>
                    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
                        <div className="py-6 px-4 md:px-6 xl:px-7.5">
                            <h4 className="text-xl font-semibold text-black dark:text-white">
                                Mes Commandes
                            </h4>
                            <button onClick={async()=>{
                                try {

                                    const collectionRef = firestore.collection('users');
                                    await collectionRef.add( {
                                        first: "Ada",
                                        last: "Lovelace",
                                        born: 1815
                                    });
                                    
                                    console.log("Document written with ID: ", collectionRef.id);
                                  } catch (e) {
                                    console.error("Error adding document: ", e);
                                  }
                            }}>click</button>
                        </div>
                        <div className="max-w-full overflow-x-auto">
                            <table className="w-full table-auto">
                                <thead>
                                    <tr className="bg-gray-2 text-left dark:bg-meta-4">
                                        <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                                            Code
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Système
                                        </th>
                                        <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                                            Type
                                        </th>

                                        <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                                            Marque, Gamme, Modèle
                                        </th>
                                        <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                                            Diagnostic
                                        </th>
                                        <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                                            Réparateur
                                        </th>
                                        <th className="py-4 px-4 font-medium text-black dark:text-white">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders?.map((order, id) => (
                                        <tr>
                                            <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11 w-max">
                                                <p className="text-black dark:text-white">
                                                    #0{order.id}
                                                </p>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <p className="text-black dark:text-white">{order.appariel?.system_type}</p>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <p className="w-max text-black dark:text-white">{order.appariel?.appariel_type}</p>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark w-mxa">
                                                <p className="w-max text-black dark:text-white">
                                                    {order.appariel?.marque_name + " - " + order.appariel?.marque_gamme + " - " + order.appariel?.marque_modele}
                                                </p>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark w-max" >
                                                {
                                                    order.symptomes?.map((symptome, index) => (
                                                        <>
                                                            {parseFloat(symptome.price) !== 0 ? <>
                                                                <div className='flex w-full'>
                                                                    <p className=" flex1 w-max text-black text-sm dark:text-white ">{symptome.name} : </p>
                                                                    <p className=" flex1 w-max text-[#003E9B] text-sm dark:text-white mx-1 ">{parseFloat(symptome.price).toFixed(2)}€</p>
                                                                </div>
                                                                {order.taches?.map((tache, indx) => (
                                                                    <>
                                                                        {
                                                                            symptome.type.includes(tache.name_tache) ? <>
                                                                                <div className="w-full">
                                                                                    <span className="text-slate-600 text-[13px] font-normal leading-tight">Système à réinstaller : </span>
                                                                                    <span className="text-blue-900 text-[13px] font-normal leading-tight">{tache.system_tache}</span>
                                                                                    {
                                                                                        tache.is_save_data ? <>
                                                                                            <span className="text-slate-600 text-[13px] font-normal leading-tight">
                                                                                                <br />Sauvegarde des données : </span>
                                                                                            <span className="text-blue-900 text-[13px] font-normal leading-tight"> {parseFloat(tache.savedata_price).toFixed(2)}€ </span>
                                                                                        </> : null
                                                                                    }
                                                                                    {
                                                                                        tache.is_install_office ? <>
                                                                                            <span className="text-slate-600 text-[13px] font-normal leading-tight">
                                                                                                <br />Installation de Office: </span>
                                                                                            <span className="text-blue-900 text-[13px] font-normal leading-tight"> {parseFloat(tache.install_office_price).toFixed(2)}€ </span>
                                                                                        </> : null
                                                                                    }

                                                                                </div>
                                                                            </> : null
                                                                        }
                                                                    </>
                                                                ))}
                                                            </> : null}
                                                        </>
                                                    ))
                                                }

                                                {
                                                    order.symptomes?.map((symptome, index) => (
                                                        <>
                                                            {parseFloat(symptome.price) === 0 ? <div className='flex'>
                                                                <p className=" flex1 w-max text-black text-sm dark:text-white ">{symptome.name}</p>
                                                                <p className=" flex1 w-max text-[#003E9B] text-sm dark:text-white mx-1 ">{parseFloat(symptome.price_admin).toFixed(2)}€</p>
                                                            </div> : null}
                                                        </>
                                                    ))
                                                }
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <p className="w-maxtext-black text-sm dark:text-white ">{order.reparateur.first_name !== undefined ? order.reparateur.first_name : "-----"} {order.reparateur.last_name !== undefined ? order.reparateur.last_name : ""} </p>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <div className="flex items-center space-x-3.5">
                                                    <NavLink to={`/commandes/${order.id}`} className="hover:text-primary">
                                                        <svg
                                                            className="fill-current"
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                                                                fill=""
                                                            />
                                                            <path
                                                                d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                                                                fill=""
                                                            />
                                                        </svg>
                                                    </NavLink>

                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </DefaultLayout>
    )
}

export default CommandScreen