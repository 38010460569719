
import './App.css';
import './axios.js';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LoginScreen from './screens/auth/LoginScreen';
import ProfileScreen from './screens/profile/ProfileScreen';
import DashboardScreen from './screens/dashboard/DashboardScreen';
import DiagnosticScreen from './screens/diagnostic/DiagnosticScreen';
import DiagnosticDetailScreen from './screens/diagnostic/DiagnosticDetailScreen';
import TarifScreen from './screens/tarif/TarifScreen';
import TarifDetailScreen from './screens/tarif/TarifDetailScreen';
import CommandScreen from './screens/commands/CommandScreen';
import CommandDetailScreen from './screens/commands/CommandDetailScreen';
import AtelierScreen from './screens/reparateur/AtelierScreen';
import LivreurScreen from './screens/livreur/LivreurScreen';
import ClientScreen from './screens/client/ClientScreen';


const router = createBrowserRouter([
  {
    path: "/",

    element: <LoginScreen />,
  },
  {
    path: "/dashboard",
    element: <DashboardScreen />,
  },
  {
    path: "/profile",
    element: <ProfileScreen />,
  },
  {
    path: "/diagnostic",
    element: <DiagnosticScreen />,
  },
  {
    path: "/diagnostic/:id",
    element: <DiagnosticDetailScreen />,
  },
  {
    path: "/tarif",
    element: <TarifScreen />,
  },
  {
    path: "/tarif/:id",
    element: <TarifDetailScreen />,
  },
  {
    path: "/commandes",
    element: <CommandScreen />,
  },
  {
    path: "/commandes/:id",
    element: <CommandDetailScreen />,
  },
  {
    path: "/ateliers",
    element: <AtelierScreen />,
  },
  {
    path: "/livreurs",
    element: <LivreurScreen />,
  },
  {
    path: "/clients",
    element: <ClientScreen />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
